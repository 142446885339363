export default {
  primary: {
    backgroundColor: '#2c220b',
    borderRadius: '500px',
    padding: '1rem 3rem',
    color: '#cb900b',
    border: 'none',
    fontWeight: 'bold',
    fontfamily: 'Forum',
    fontSize: ['1rem', '', '1.25rem'],
    textTransform: 'uppercase',
    transition: 'all ease-in-out 0.6s',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  },
  secondary: {
    backgroundColor: 'primary',
    borderRadius: '500px',
    padding: '1rem 3rem',
    color: 'white',
    border: 'none',
    fontWeight: 'bold',
    fontfamily: 'Forum',
    fontSize: ['1rem', '', '1.25rem'],
    textTransform: 'uppercase',
    transition: 'all ease-in-out 0.6s',
    ':hover': {
      backgroundColor: 'black',
      color: 'white'
    }
  }
}
