export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      padding: '0.25rem 1rem'
    },
    '.containerScrolled': {
      backgroundColor: 'primary',
      padding: '0.25rem 1rem',
      color: 'white',
      boxShadow: '0px 6px 7px #00000021'
    },

    '.logoLocationContainer': {
      padding: '0rem'
    },
    '.logo': {
      mx: '1.5rem',
      padding: '0rem',
      img: {
        filter: 'unset',
        padding: '0rem'
      }
    },
    '.logoScrolled': {
      mx: '1.5rem',
      padding: '0rem',
      img: { maxHeight: '70px', padding: '0rem' }
    },

    '.navItem': {
      padding: '0rem 1rem',
      transition: 'all ease-in-out 0.5s',
      display: 'flex',
      alignItems: 'center',
      a: {
        fontFamily: 'body',
        color: 'inherit',
        fontSize: ['1rem']
      },
      ':hover': {
        padding: '0rem 2rem'
      }
    },

    '.hamburger': {
      borderRadius: '100px',
      height: '50px',
      width: '50px',
      backgroundColor: 'primary',
      p: '0.75rem',
      border: 'solid 1px',
      borderRadius: '500px',
      right: ['1rem', '', '2rem'],
      // padding:
      div: {
        borderRadius: '10px'
      }
    },

    // ? === Nav Menu ===
    '.navMenuLogo': {
      position: 'static',
      maxWidth: '200px',
      marginBottom: '1rem',
      borderBottom: '1px solid',
      marginBottom: '2rem',
      paddingBottom: '2rem'
    },

    '.navMenu, .navMenuOpen': {
      color: 'white',
      backgroundColor: 'primary',
      padding: ['2rem', '', '', '2rem'],
      width: ['100%', '100%', '100%', '100%'],
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      transform: 'unset',
      position: 'fixed',
      left: '0rem',
      right: 'unset',
      '.navItem': {
        textAlign: 'left',
        margin: ['0.5rem', '0.5rem', '0.5rem', '0.5rem', '0.5rem'],
        a: {
          fontSize: ['1.5rem', '1.75rem', '2rem', '2rem', '2.25rem'],
          letterSpacing: '0px',
          fontWeight: 'bold',
          color: 'white',
          transition: 'all ease-in-out 0.7s',
          textDecoration: 'none',
          ':hover': {
            color: 'secondaryDark'
          }
        }
      },
      '.seperatorLine': {
        display: 'none'
      },
      '.navItemDropdownMenu': {
        position: 'static',
        background: 'transparent',
        textShadow: 'unset'
      }
    },

    '.navMenu': {
      width: ['85%', '60%', '50%', '40%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      transform: 'unset',
      position: 'fixed',
      color: 'white',
      backgroundColor: 'transparent',
      height: '100vh',
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      '.navItem': {
        textAlign: 'left',
        margin: ['0.5rem', '0.5rem', '0.5rem', '0.5rem', '0.5rem'],
        a: {
          fontSize: ['1.5rem', '1.75rem', '2rem', '2rem', '2.25rem'],
          letterSpacing: '0px',
          fontWeight: 'bold',
          color: 'white',
          transition: 'all ease-in-out 0.7s',
          textDecoration: 'none',
          ':hover': {
            opacity: '0.5'
          }
        }
      }
    },

    '.navBlock': {
      width: ['15%', '40%', '50%', '60%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      height: '100vh',
      position: 'fixed'
    },
    '.navBlockOpen': {
      position: 'fixed',
      backdropFilter: 'blur(1000042px)',
      backgroundImage: 'url(https://img.freepik.com/free-vector/soft-blurred-background_1034-273.jpg?w=2000)',
      backgroundColor: '#000000',
      opacity: 0.98,
      backgroundSize: 'cover',
      filter: 'brightness(0.1)'
    },
    '.phoneSocialContainer': {
      mt: '2rem',
      alignItems: 'flex-start'
    }
  },

  footer: {
    borderTop: '1px solid'
  },

  ctaWidget: {
    a: {
      borderRadius: ['', '', '', '100px']
    }
  },

  loadingPage: {
    backgroundColor: 'white'
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    color: 'text',
    textTransform: 'uppercase',
    fontSize: ['1.75rem', '2rem', '2.5rem', '3rem'],
    borderColor: 'primary'
  },
  subtitle: {
    textTransform: 'capitalize',
    color: 'primary',
    textShadow: '1px 1px grey',
    fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem']
  },
  text: {
    lineHeight: '1.75',
    fontSize: ['1rem', '', '1.1rem', '1.2rem'],
    '*': {
      color: 'inherit',
      fontSize: 'inherit'
    }
  },

  sideBySide1: {
    padding: '0.5rem 0.75rem 1rem 1rem',
    minHeight: '75vh',
    overflow: 'hidden',
    '.lazyload-wrapper': {
      maxHeight: '75vh',
      position: 'relative',
      left: ['', '', '', '2rem'],
      justifyContent: 'flex-end',
      '.image1': {
        height: '80%',
        my: 'auto',
        zIndex: '1',
        minHeight: '50vh'
      },
      '.image3': {
        position: 'relative',
        left: ['', '', '', '-4rem'],
        minHeight: '50vh',
        zIndex: '0',
        height: '90%',
        my: 'auto'
      }
    },
    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      textAlign: 'left'
    },
    '.title': {
      variant: 'customVariants.title',
      order: '1'
    },
    '.subtitle': { variant: 'customVariants.subtitle', order: '2', mb: '1rem' },
    '.text': {
      variant: 'customVariants.text',
      mb: '1.5rem',
      order: '3'
    },
    '.linksContainer': {
      order: '4'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    'svg.slick-arrow': {
      backgroundColor: '#cea3439c',
      color: 'white',
      width: '30px',
      height: '30px',
      padding: '0.25rem',
      bottom: '0rem',
      left: '1rem',
      right: 'unset',
      top: 'unset',
      borderRadius: '100px'
    },
    'svg.slick-next': {
      left: '4rem'
    },
    '.section': {
      margin: '1rem'
    },
    position: 'relative',
    '::before': {
      content: "''",
      width: '100%',
      background: 'radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.14329481792717091) 40%, rgba(0,0,0,1) 100%)',
      height: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: '1',
      transform: 'translate(-50%,-50%)'
    },
    '.hero_content_container': {
      marginTop: 'auto',
      bottom: '3rem',
      top: 'unset',
      transform: 'unset',
      zIndex: '10'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      fontSize: ['3rem', '3rem', '4rem', '5rem'],
      mb: '0rem',
      textShadow: '1px 1px black'
    },

    '.subtitle': {
      variant: 'customVariants.subtitle'
    },

    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      mb: '1.5rem'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    backgroundColor: 'background',
    padding: ['0rem 1rem 1rem', '', '', '0rem 0.5rem 1rem'],
    flexWrap: ['wrap', 'wrap', 'wrap', 'wrap'],
    margin: '1rem 0rem 0rem',
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      backgroundColor: '#202325',
      width: ['', '', '80%'],
      padding: '1rem',
      textTransform: 'uppercase'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['primary', '', '', 'primary'],
      color: 'light'
    },
    '.date': {
      backgroundColor: 'secondary',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer'
    },
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1',
    padding: '0rem',
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['3rem', '3rem', '4rem', '5rem'],
      color: 'primary'
    },
    '.subtitle': {
      variant: 'customVariants.title',
      color: 'white',
      textShadow: '1px 1px black'
    },

    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageBeer: {
    variant: 'customVariants.sideBySide1'
  },

  homepageVenue: {
    variant: 'customVariants.sideBySide1',
    color: 'white',
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['3rem', '3rem', '4rem', '5rem'],
      color: 'white',
      textShadow: '1px 1px black'
    },
    '.linksContainer': {
      order: '4',
      alignItems: 'center',
      justifyContent: ['flex-start', '', 'center']
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    backgroundImage: 'none'
  },

  // ? ========================
  // ? =====  Menu page  ======
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {},

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {},

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    textAlign: 'left',
    position: 'relative',
    alignItems: 'flex-start',
    '> *': {
      zIndex: '1'
    },
    '::before': {
      content: "''",
      backgroundColor: ['#ffffffcf', '', '#ffffffe8'],
      width: ['100%', '', '50%'],
      height: '100%',
      position: 'absolute',
      top: '0%',
      right: '0%',
      zIndex: '0'
    },
    '.title': {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      maxWidth: '500px',
      width: '100%'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      maxWidth: '500px',
      width: '100%',
      opacity: '0.7',
      mb: '2rem'
    },
    '.text': {
      maxWidth: '500px',
      width: '100%',
      mb: '2rem'
    },
    backgroundPosition: 'center center',
    color: 'text',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    pl: ['', '', '52.5%'],
    form: {
      maxWidth: '500px'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'secondary',
      borderRadius: '0px',
      padding: '1.5rem 0.5rem',
      color: 'text',
      fontFamily: 'heading',
      '::placeholder': {
        color: 'text'
      }
    },
    textarea: {
      '::placeholder': {
        color: 'text',
        fontWeight: '300',
        fontFamily: 'heading'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      padding: '0.5rem 1rem',
      height: 'unset',
      width: 'auto',
      marginTop: '1.5rem',
      ':hover': {
        borderColor: 'dark',
        backgroundColor: 'dark'
      }
    }
  },

  locationMap: {
    '.content_container': {
      backgroundColor: 'primary'
    },
    backgroundColor: 'primary',

    order: '3',

    h3: {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.5rem', '1.75rem', '1.75rem']
    },
    '.dayContainer': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    },
    iframe: {
      opacity: '0.9'
    }
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {},

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
